<template>
  <div id="page-turnover">
    <v-container grid-list-xl fluid>
      <v-card>
        <v-card-title>{{
          $vuetify.lang.t('$vuetify.different.turnover')
        }}</v-card-title>
        <v-card-text>
          <v-form ref="turnoverForm" lazy-validation v-model="valid">
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-menu
                  v-model="fromPiker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="250px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="from"
                      :label="$vuetify.lang.t('$vuetify.dates.from') + '*'"
                      prepend-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    first-day-of-week="1"
                    v-model="from"
                    @input="fromPiker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-menu
                  v-model="toPiker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="250px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="to"
                      :label="$vuetify.lang.t('$vuetify.dates.to') + '*'"
                      prepend-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    first-day-of-week="1"
                    v-model="to"
                    @input="toPiker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
          <small>*{{ $vuetify.lang.t('$vuetify.different.indicate') }}</small>
          <v-divider></v-divider>
          <v-row class="mt-4">
            <v-col cols="12" sm="6" md="3">
              <v-list flat subheader one-line>
                <v-subheader>
                  <v-checkbox v-model="allPlatforms"></v-checkbox>
                  {{ $vuetify.lang.t('$vuetify.buttons.all') }}
                  <v-spacer></v-spacer>
                  {{ $vuetify.lang.t('$vuetify.different.platforms') }}
                </v-subheader>
                <v-list-item v-for="item in platforms" :key="item.id">
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox v-model="item.selected"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content
                      @click="item.selected = !item.selected"
                    >
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="6" md="3">
              <v-list flat subheader one-line>
                <v-subheader>
                  <v-checkbox v-model="allCustomers"></v-checkbox>
                  {{ $vuetify.lang.t('$vuetify.buttons.all') }}
                  <v-spacer></v-spacer>
                  {{ $vuetify.lang.t('$vuetify.user.customers') }}
                </v-subheader>
                <v-list-item v-for="item in customers" :key="item.id">
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox v-model="item.selected"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content
                      @click="item.selected = !item.selected"
                    >
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="6" md="3">
              <v-list flat subheader one-line>
                <v-subheader>
                  <v-checkbox v-model="allManufacturers"></v-checkbox>
                  {{ $vuetify.lang.t('$vuetify.buttons.all') }}
                  <v-spacer></v-spacer>
                  {{ $vuetify.lang.t('$vuetify.manufacturer.many') }}
                </v-subheader>
                <v-list-item v-for="item in manufacturers" :key="item.id">
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox v-model="item.selected"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content
                      @click="item.selected = !item.selected"
                    >
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            @click="getStatistic('stat')"
            :disabled="!valid"
          >
            <v-icon left small>mdi-eye</v-icon>
            {{ $vuetify.lang.t('$vuetify.buttons.view') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="mt-4">
        <v-card-title>{{
          $vuetify.lang.t('$vuetify.different.result')
        }}</v-card-title>
        <v-toolbar flat color="white">
          <v-text-field
            text
            solo
            flat
            clearable
            prepend-icon="search"
            :placeholder="$vuetify.lang.t('$vuetify.toolbar.search')"
            v-model="search"
            hide-details
            class="hidden-sm-and-down"
          ></v-text-field>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <v-data-table
            :headers="headers"
            :items="results"
            fixed-header
            class="elevation-1"
            item-key="id"
            :search="search"
          >
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-card class="mt-4">
        <v-card-title>{{
          $vuetify.lang.t('$vuetify.different.items')
        }}</v-card-title>
        <v-toolbar flat color="white">
          <v-text-field
            text
            solo
            flat
            clearable
            prepend-icon="search"
            :placeholder="$vuetify.lang.t('$vuetify.toolbar.search')"
            v-model="searchItems"
            hide-details
            class="hidden-sm-and-down"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            @click="getStatistic('items')"
            :disabled="!valid"
          >
            <v-icon left small>mdi-eye</v-icon>
            {{ $vuetify.lang.t('$vuetify.buttons.view') }}
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <v-data-table
            :headers="compItemHeaders"
            :items="items"
            fixed-header
            class="elevation-1"
            item-key="articleordernumber"
            :search="searchItems"
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <AppLoader :dialog.sync="loading"></AppLoader>
  </div>
</template>

<script>
import AppLoader from '@/components/AppLoader'

export default {
  components: {
    AppLoader
  },
  data() {
    return {
      valid: true,
      fromPiker: false,
      toPiker: false,
      loading: false,
      from: null,
      to: null,
      search: null,
      searchItems: null,
      allPlatforms: false,
      allCustomers: false,
      allManufacturers: false,
      rules: {
        required: (v) =>
          !!v || this.$vuetify.lang.t('$vuetify.validation.required'),
        requiredArray: (v) =>
          !!v.length || this.$vuetify.lang.t('$vuetify.validation.required'),
        max191: (v) =>
          (v && v.length <= 191) ||
          this.$vuetify.lang.t('$vuetify.validation.max191')
      },
      platforms: [
        {
          id: 'amazon_de',
          text: 'Amazon_de',
          selected: false,
          type: 'platform'
        },
        {
          id: 'amazon_eu',
          text: 'Amazon_eu',
          selected: false,
          type: 'platform'
        },
        { id: 'ebay', text: 'Ebay', selected: false, type: 'platform' },
        { id: 'outdoor', text: 'Outdoor', selected: false, type: 'platform' },
        { id: 'fanatik', text: 'Fanatik', selected: false, type: 'platform' },
        {
          id: 'fanatik_uk',
          text: 'Fanatik UK',
          selected: false,
          type: 'platform'
        },
        {
          id: 'fanatik_nl',
          text: 'Fanatik NL',
          selected: false,
          type: 'platform'
        },
        { id: 'triton', text: 'Triton', selected: false, type: 'platform' }
      ],
      customers: [
        { id: 'dealers', text: 'Dealers', selected: false, type: 'customer' },
        {
          id: 'dealer_plus',
          text: 'Dealers+',
          selected: false,
          type: 'customer'
        },
        {
          id: 'dealer_minus',
          text: 'Dealers-',
          selected: false,
          type: 'customer'
        },
        {
          id: 'dealer_invoiced',
          text: 'Dealers invoiced',
          selected: false,
          type: 'customer'
        },
        {
          id: 'dealer_open',
          text: 'Dealers open',
          selected: false,
          type: 'customer'
        },
        {
          id: 'dealer_reserved',
          text: 'Dealers reserved',
          selected: false,
          type: 'customer'
        },
        {
          id: 'end_customers',
          text: 'Customers',
          selected: false,
          type: 'customer'
        }
      ],
      manufacturers: [],
      manufacturersColumns: [],
      results: [],
      items: [],
      headers: []
    }
  },
  watch: {
    allPlatforms(val) {
      this.selectAll('platforms', val)
    },
    allCustomers(val) {
      this.selectAll('customers', val)
    },
    allManufacturers(val) {
      this.selectAll('manufacturers', val)
    }
  },
  metaInfo() {
    return {
      title: this.$vuetify.lang.t('$vuetify.different.turnover')
    }
  },
  mounted() {
    this.from = this.firstDayInThisMonth()
    this.to = new Date().toJSON().slice(0, 10)

    this.init()
  },
  computed: {
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }
    },
    compItemHeaders() {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.different.title'),
          value: 'name'
        },
        { text: 'SKU', value: 'articleordernumber' },
        {
          text: this.$vuetify.lang.t('$vuetify.order.quantity'),
          value: 'quantity'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.order.inStock'),
          value: 'in_stock'
        },
        { text: this.$vuetify.lang.t('$vuetify.order.sum'), value: 'amount' }
      ]
    }
  },
  methods: {
    init() {
      let data = {
        project_id: 2
      }
      this.loading = true
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/turnover/init',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          if (response.data.success) {
            this.manufacturers = response.data.manufacturers
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
    getStatistic(type) {
      if (this.$refs.turnoverForm.validate()) {
        let data = {
          date_start: this.from,
          date_end: this.to,
          project_id: 2,
          platforms: this.platforms,
          customers: this.customers,
          manufacturers: this.manufacturers,
          type: type
        }

        this.loading = true
        this.$axios
          .post(
            this.$store.getters.getApiUrl + 'api/turnover/get',
            data,
            this.compAuthHeader
          )
          .then((response) => {
            if (response.data.success && type === 'stat') {
              this.headers = response.data.table_headers
              this.results = response.data.results
              this.manufacturersColumns = response.data.manufacturers_columns
            } else if (response.data.success && type === 'items') {
              this.items = response.data.items
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log(error)
          })
      }
    },
    firstDayInThisMonth() {
      let now = new Date()
      return new Date(now.getFullYear(), now.getMonth(), 2)
        .toJSON()
        .slice(0, 10)
    },
    selectAll(propertyKey, val) {
      if (propertyKey) {
        this[propertyKey].map(function(item, index) {
          item.selected = val
        })
      }
    }
  }
}
</script>
